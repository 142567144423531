export function parallax() {
	jQuery(".move-down figure").parallaxContent({ shift: 22 });
	jQuery(".move-up figure").parallaxContent({
		shift: 22,
		direction: "reverse",
	});
}

export function menu() {
	(function ($) {
		const menu = $("#menu");
		const placeholderImage = menu.find(".placeholder");
		const mainImage = menu.find(".main-menu");
		const images = menu.find(".placeholder, .main-menu");

		images.on("mouseenter mouseleave", function (e) {
			e.preventDefault();

			placeholderImage.toggleClass("hidden");
			mainImage.toggleClass("hidden");
		});
	})(jQuery);
}

export function gallery() {
	jQuery(".dt-css-grid").justifiedGallery({
		rowHeight: 386,
		maxRowHeight: 386,
		lastRow: "justify",
		margins: 24,
		captions: false,
	});
}
