import bindJustifiedGalleryToJQuery from "justifiedGallery/dist/js/jquery.justifiedGallery";
import "justifiedGallery/dist/css/justifiedGallery.css";
import "./scss/styles.scss";

import "parallax_content";
import "gsap/TweenMax";
import "gsap/TweenLite";
import "gsap/CSSPlugin";

bindJustifiedGalleryToJQuery(null, jQuery);

import { parallax, menu, gallery } from "./scripts/app";

window.addEventListener("DOMContentLoaded", parallax);
window.addEventListener("DOMContentLoaded", menu);
window.addEventListener("DOMContentLoaded", gallery);

// See https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
	import.meta.hot.accept("./app.js", (new_app) => {
		new_app?.app();
	});
}
